















































import { Component, Vue } from "vue-property-decorator";
import { abilities } from "@/plugins/casl/abilities";
import * as navbarHelper from "@/utils/navbarhelper";
import { MenuButton } from "@/utils/navbarhelper/types";

// TODO create sub navbars and use main navbar for topics like users, permissions, configurator, shop

/**
 * AdminNavbar component
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component
export default class AdminNavbar extends Vue {
    // Routes that the user has access to
    private filteredMenuButtons: Array<MenuButton> = [];

    /**
     * Function will be automatic called by Vue.js (see vue lifecycle)
     * Function fetches data, initialize variables etc.
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private created() {
        this.filterMenuButtons();
        abilities.on("updated", () => {
            this.filterMenuButtons();
        });
    }

    /**
     * Saves the routes in filteredMenuButtons that the user has access to
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private filterMenuButtons() {
        const menuButtons: Array<MenuButton> = [
            {
                to: { name: "AdminPanelShopDealManagement" },
                exact: false,
                text: this.$tc("shop.deal", 100).toString(),
            },
            {
                to: { name: "AdminPanelShopArticleManagement" },
                exact: true,
                text: this.$tc("shop.article", 100).toString(),
            },
            {
                to: { name: "AdminPanelShopArticleCategoryManagement" },
                exact: false,
                text: this.$tc("shop.article.category", 100).toString(),
            },
            {
                to: { name: "AdminPanelShopArticleGroupManagement" },
                exact: false,
                text: this.$tc("shop.article.group", 100).toString(),
            },
            {
                to: { name: "AdminPanelShopArticleProducerManagement" },
                exact: false,
                text: this.$tc("shop.article.producer", 100).toString(),
            },
            {
                to: { name: "AdminPanelShopArticlePriceClassManagement" },
                exact: false,
                text: this.$tc("shop.article.priceClass", 100).toString(),
            },
            {
                to: { name: "AdminPanelPropertyManagement" },
                exact: false,
                text: this.$tc("shop.article.property", 100).toString(),
            },
            {
                to: {
                    name: "AdminPanelConfiguratorConfigurationGroupManagement",
                },
                exact: true,
                text: this.$tc(
                    "configurator.configuration.group",
                    100
                ).toString(),
            },
            {
                to: {
                    name: "AdminPanelConfiguratorConfiguredConfigurationManagement",
                },
                exact: true,
                text: this.$tc(
                    "configurator.configuredConfiguration",
                    100
                ).toString(),
            },
            {
                to: {
                    name: "AdminPanelConfiguratorCompatibilityManagement",
                },
                exact: true,
                text: this.$tc(
                    "configurator.articleCompatibility",
                    100
                ).toString(),
            },
            {
                to: {
                    name: "AdminPanelUserCompanyManagement",
                },
                exact: true,
                text: this.$tc("user.company", 100).toString(),
            },
            {
                to: {
                    name: "AdminPanelUserManagement",
                },
                exact: true,
                text: this.$tc("user", 100).toString(),
            },
            {
                to: {
                    name: "AdminPanelUserPermissionGroupManagement",
                },
                exact: true,
                text: this.$tc("user.permissionGroup", 100).toString(),
            },
            {
                to: {
                    name: "AdminAnalyticsManagement",
                },
                exact: true,
                text: this.$tc("user.analytics"),
            },
        ];

        this.filteredMenuButtons = navbarHelper.filterMenuButtons(menuButtons);
    }
}
